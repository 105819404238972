(function ($) {
    $(document).on('ready', function () {
        // Post loading engine.
        $('.posts-browser').on('click', '[data-action="load-more"]', function (event) {
            event.preventDefault();

            var $element = $(this);
            var $browser = $element.parents('.posts-browser');
            var $overlay = $browser.find('.posts-browser__overlay');
            var $postList = $browser.find('.posts-browser__posts-list');
            var templateArgs = $postList.data('template-args');

            $browser.find('.posts-browser__posts-list');
            $(document.body).css({ 'cursor': 'wait' });

            var args = $browser.data('query-args');
            args['paged']++;

            $(document.body).css({ 'cursor': 'wait' });
            $browser.addClass('posts-browser--loading');
            $overlay.addClass('posts-browser__overlay--visible');

            var data = {
                action: 'cps_load_posts',
                args: args,
                template_args: templateArgs
            };
            $.post(CPS.ajaxUrl, data, function (response) {
                $postList.append(response.html);
                $postList.find('visually-hidden').last().trigger('focus');
                $overlay.removeClass('posts-browser__overlay--visible');

                if (response) {
                    $(document.body).css({ 'cursor': 'default' });
                    $browser.removeClass('posts-browser--loading');

                    if (args['posts_per_page'] > response.found) {
                        $element.parent().hide();
                    }
                    else {
                        $element.parent().show();
                    }
                }
            });
        });

        // Post quering engine.
        $('.posts-browser').on('click', '[data-query-arg], .caledarp-calendar-day-link', function (event) {
            if ( $(this).is('.caledarp-calendar-day-link') ) {
                event.preventDefault();
            }

            var $element = $(this);
            var $browser = $element.parents('.posts-browser');
            var $postList = $browser.find('.posts-browser__posts-list');
            var $overlay = $browser.find('.posts-browser__overlay');
            var args = $browser.data('query-args');
            var templateArgs = $postList.data('template-args');

            if( $element.is('.caledarp-calendar-day-link') ) {
                var href = $element.attr('href');
                var url;
                if ( href.indexOf('http') !== 0 ) {
                    url = new URL(href, window.location.href);
                } else {
                    url = new URL(href);
                }
                var urlParams = new URLSearchParams(url.search);
                var val = urlParams.get('to');
                var arg = 'day';
                if (
                    $( this ).is('.caledarp-calendar-day-link') &&
                    $( this ).parent().is('.selected')
                ) {
                    val = '';
                    $element.parent().removeClass('selected');
                }
            }
            else {
                var arg = $element.data('query-arg');
            
                if( arg == 's' ) {
                    var val = $element.parents('.frm-search').find('input').val();
                }
                else if( arg == 'clear' ) {
                    var newArgs = JSON.parse( $browser.attr( 'data-query-args' ) );
                }
                else {
                    var val = $element.data('value');
                }
            }

            const isList = $(this).data('type') === 'list';

            if( ( arg === 'clear' && JSON.stringify(args) !== JSON.stringify(newArgs) ) || args[arg] !== val || isList ) {
                if( arg !== 'clear' ) {
                    if ( arg === 'tax' ) {
                        const taxName = $(this).data( 'taxonomy' );
                        let taxonomies = {};
                        let items = [];

                        // Disabled to support just one category
                        if ( args.hasOwnProperty( arg ) ) {
                            //taxonomies = args[arg];
                        }
                        if ( taxonomies.hasOwnProperty( taxName ) ) {
                            items = taxonomies[taxName];
                        }
                        const idx = items.indexOf( val );
                        if ( idx >= 0 ) {
                            items.splice( idx, 1 );
                        } else {
                            items.push( val );
                        }

                        taxonomies[taxName] = items;
                        args[arg] = taxonomies;
                    } else if(arg === 'category__in' || arg === 'category__not_in') {
                        if ( args.hasOwnProperty( arg ) ) {
                            let items = args[arg];
                            const idx = items.indexOf( val );
                            if ( idx >= 0 ) {
                                items.splice( idx, 1 );
                            } else {
                                items.push( val );
                            }
                            args[arg] = items;
                        } else {
                            args[arg] = [ val ];
                        }
                    } else {
                        args[arg] = val;
                    }

                    args['paged'] = 1;

                    $browser.find('.frm-clear').prop('disabled', false);
                }
                else {
                    args = newArgs;
                }
                if ( arg === 'from' && args.hasOwnProperty( 'month' ) ) {
                    delete args['month'];
                }

                $(document.body).css({ 'cursor': 'wait' });
                $browser.addClass('posts-browser--loading');
                $overlay.addClass('posts-browser__overlay--visible');

                var data = {
                    action: 'cps_load_posts',
                    args: args,
                    template_args: templateArgs
                };
                $.post(CPS.ajaxUrl, data, function (response) {
                    $(document.body).css({ 'cursor': 'default' });
                    $browser.removeClass('posts-browser--loading');
                    $overlay.removeClass('posts-browser__overlay--visible');
    
                    if (response) {
                        $postList.html(response.html);
                        $postList.find('visually-hidden').last().trigger('focus');

                        if (args['posts_per_page'] > response.found) {
                            $browser.find('[data-action="load-more"]').parent().hide();
                        }
                        else {
                            $browser.find('[data-action="load-more"]').parent().show();
                        }

                        if( "eventsData" in response ) {
                            if( response.eventsData['prev_day'] ) {
                                $browser.find('.posts-browser__nav-prev-link').data('value', response.eventsData['prev_day']).prop('disabled', false);
                            }
                            else {
                                $browser.find('.posts-browser__nav-prev-link').prop('disabled', true);
                            }
                            if( response.eventsData['next_day'] ) {
                                $browser.find('.posts-browser__nav-next-link').data('value', response.eventsData['next_day']).prop('disabled', false);
                            }
                            else {
                                $browser.find('.posts-browser__nav-next-link').prop('disabled', true);
                            }
                            $browser.find('.posts-browser__nav-title').text(response.eventsData['current_label']);
                        }
                    }
                });
            }

            if ( val ) {
                if( arg == 'day' ) {
                    $element.parents('.wp-calendar-plus').find('.caledarp-calendar-day-link').attr('aria-pressed', false);
                    $element.parents('.wp-calendar-plus').find('.selected').removeClass('selected');
                    $element.parents('td').addClass('selected');
                    $browser.trigger('change', val);
                }
                else {
                    $element.parents('.frm-btn-list').find('button').attr('aria-pressed', false);
                }

                $element.attr('aria-pressed', true);
            }
            else {
                if( arg === 'clear' ) {
                    $browser.find('[aria-pressed="true"]').attr('aria-pressed', false);
                    $browser.find('input').val('');
                    $element.prop('disabled', true);
                    $browser.trigger('clearFilters');
                }
            }
        });
    });
})(jQuery);