/* https://getbootstrap.com/docs/5.0/components/ */

export { default as Alert } from 'bootstrap/js/src/alert';
//export { default as Button } from 'bootstrap/js/src/button';
//export { default as Carousel } from 'bootstrap/js/src/carousel';
//export { default as Popover } from 'bootstrap/js/src/popover';
//export { default as ScrollSpy } from 'bootstrap/js/src/scrollspy';
//export { default as Tab } from 'bootstrap/js/src/tab';
//export { default as Tooltip } from 'bootstrap/js/src/tooltip';

export {default as Collapse} from "bootstrap/js/src/collapse";
export {default as Dropdown} from "bootstrap/js/src/dropdown";
export {default as Modal} from "bootstrap/js/src/modal";
export {default as Offcanvas} from "bootstrap/js/src/offcanvas";

import CPSThemeHelpers from "./assets/cp-theme-engine/helpers.js";
import "./assets/cp-theme-engine/posts-browser.js";
import "./assets/cp-theme-engine/events-calendar.js";

(function ($) {
	// Directory tweaks.
	$(document).on("ready", function () {
		$(".cp-dir").each(function (index) {
			$(this).find(".cp-dir-control-select").dropdownSelect();
		});

		if ($(".cp-dir").length) {
			//prefilter based on GET
			var params = getSearchParameters();
			$.each(params, function (key, value) {
				var target = $("[data-get-value=\"" + key + "\"][data-slug=\"" + value + "\"]");
				if (target.length) {
					target.parent().prependTo(target.parents("ul"));
					target.trigger("click");
				}
			});

			function getSearchParameters() {
				var prmstr = window.location.search.substr(1);
				return prmstr != null && prmstr != "" ? transformToAssocArray(prmstr) : {};
			}

			function transformToAssocArray(prmstr) {
				var params = {};
				var prmarr = prmstr.split("&");
				for (var i = 0; i < prmarr.length; i++) {
					var tmparr = prmarr[i].split("=");
					params[tmparr[0]] = tmparr[1];
				}
				return params;
			}
		}

		$( '.posts-browser-filters__dropdown-menu .dropdown-item' ).on( 'click', function( e ) {
			e.preventDefault();

			const $parent = $( this ).closest( '.posts-browser-control-select' );
			const $currentItem = $( this );

			// Check if the clicked item is already selected.
			if ( $currentItem.attr( 'aria-pressed' ) !== 'true' ) {
				// Update the selected value if the item is not already selected.
				$parent.find( '.frm-dropdown__default' ).text( $currentItem.data( 'name' ) );

				// Reset aria-pressed for all dropdown items.
				$parent.find( '.dropdown-item' ).attr( 'aria-pressed', 'false' );

				// Set aria-pressed to true for the selected item.
				$currentItem.attr( 'aria-pressed', 'true' );
			}

			// Refocus the dropdown toggle button.
			$parent.find( '.frm-dropdown__toggle' ).focus();
		} );

		$( '.posts-browser, .events-calendar' ).on( 'clearFilters', function() {
			const $defaultElement = $( this ).find( '.posts-browser-control-select .dropdown-item--default' );

			// Update the dropdown default text with the default element's name.
			$( this )
				.find( '.posts-browser-control-select .frm-dropdown__default' )
				.text( $defaultElement.data( 'name' ) );

			// Reset aria-pressed for all dropdown items.
			$( this )
				.find( '.posts-browser-control-select .dropdown-item' )
				.attr( 'aria-pressed', 'false' );

			// Set aria-pressed to true for the default item.
			$defaultElement.attr( 'aria-pressed', 'true' );
		} );

		/*
		$('.events-browser').on('change', function(event, val) {
			updateEventsBrowserTopNav($(this), val);
		});

		function updateEventsBrowserTopNav($browser, val) {
			let date = new Date(val);
			if (date) {
				const dateText = date.toLocaleDateString(undefined, {
					month: 'long',
					day: 'numeric'
				});
				$browser.find('.posts-browser__nav-title')
					.text(dateText);
			}

			const $prevLink = $browser.find('.posts-browser__nav-prev-link');
			updateBrowserNavButton($prevLink, date, -1);

			const $nextLink = $browser.find('.posts-browser__nav-next-link');
			updateBrowserNavButton($nextLink, date, 1);
		}

		function updateBrowserNavButton($button, date, increment) {
			const fromDate = new Date(date);
			const incrementedDay = date.getDate() + increment;

			fromDate.setDate(incrementedDay);
			const fromDateText = `${fromDate.getFullYear()}-${fromDate.getMonth() + 1}-${fromDate.getDate()}`;

			$button.attr('href', `?from=${fromDateText}`);
		}
		*/

		$('.events-browser').on('clearFilters', function() {
			const args = JSON.parse( $(this).attr( 'data-query-args' ) );
			console.log(args);
			//updateEventsBrowserTopNav($(this), args.from);
		});
	});

	// Handles select based on buttons.
	$.fn.extend( {
		dropdownSelect: function() {
			this.on( 'click', '[data-target][data-value]', function() {
				const val = $( this ).data( 'value' );
				const $dropdownToggle = $( this ).closest( '.frm-dropdown' ).find( '.frm-dropdown__toggle' );

				// Update aria-pressed attributes.
				$( this ).attr( 'aria-pressed', true ).siblings( '[data-target][data-value]' ).attr( 'aria-pressed', false );

				// Update the hidden input value and trigger change.
				$( '#' + $( this ).data( 'target' ) ).val( val ).trigger( 'change' );

				// Refocus the dropdown toggle button.
				$dropdownToggle.focus();
			} );

			this.find( 'select' ).on( 'change', _dropdownSelectChange );

			return this;
		}
	} );

	function _dropdownSelectChange() {
		const valText = $(this).find("option:selected").text();

		const $selectUI = $(".dropdown[data-controls=\"" + $(this).attr("id") + "\"]");
		if ($selectUI.length) {
			$selectUI.addClass("frm-dropdown--selected").find(".frm-dropdown__current").text(valText);
			if ($(this).val() == "0") {
				$selectUI.removeClass("frm-dropdown--selected");
			}
		}
	}

	$(".slider-items").each((idx, el) => {
		if ($(el).children()) {
			const options = {
				adaptiveHeight: true,
				arrows: true,
				centerMode: false,
				dots: true,
				appendArrows: $(el).parent().find(".slider-nav-arrows"),
				appendDots: $(el).parent().find(".slider-nav-dots"),
				draggable: true,
				infinite: false,
				mobileFirst: true,
			};
			let responsiveBreakpoint = $(el).data("responsive");
			if (responsiveBreakpoint) {
				options.responsive = [
					{
						breakpoint: parseInt(responsiveBreakpoint),
						settings: "unslick"
					}
				];
			}
			if ($(el).data("variable-width")) {
				options.variableWidth = true;
			}
			$(el).slick(options);
		}
	});

	$(".news-events-grid__grid").each((idx, el) => {
		if ($(el).children()) {
			const options = {
				adaptiveHeight: true,
				arrows: false,
				centerMode: false,
				dots: true,
				draggable: true,
				infinite: false,
				mobileFirst: true,
				slidesToShow: 1,
				slidesToScroll: 1
			};
			const responsiveBreakpoint = $(el).data("responsive");
			if (responsiveBreakpoint) {
				options.responsive = [
					{
						breakpoint: parseInt(responsiveBreakpoint),
						settings: "unslick"
					}
				];
				if (window.innerWidth < responsiveBreakpoint) {
					const $news = $(el).find(".news-events-grid__news-item");
					const $newsWrapper = $("<div class=\"news-events-grid__items-slider grid-slider-items\"></div>");
					$newsWrapper.append($news);
					$(el).remove($news);

					$(el).append($newsWrapper);
					const $newsNav = $(el).parent().find(".news-events-grid__items-nav--news");
					$newsWrapper.after($newsNav);

					const $events = $(el).find(".news-events-grid__events-item:not(.news-events-grid__item--podcast)");
					const $eventsWrapper = $("<div class=\"news-events-grid__items-slider grid-slider-items\"></div>");
					$eventsWrapper.append($events);

					const $podcast = $(el).find(".news-events-grid__item--podcast");
					$eventsWrapper.append($podcast);
					$(el).remove($podcast);

					$(el).append($eventsWrapper);
					const $eventsNav = $(el).parent().find(".news-events-grid__items-nav--events");
					$eventsWrapper.after($eventsNav);
				}
			}
			if ($(el).data("variable-width")) {
				options.variableWidth = true;
			}
			$(el).find(".grid-slider-items").each((idx, slider) => {
				$(slider)
					.slick(options)
					.on("breakpoint", function (event, slick, breakpoint) {
						$(el).find(".news-events-grid__items-nav").remove();

						const $podcast = $(slider).find(".news-events-grid__item--podcast");
						$(el).append($podcast);

						const $items = $(slider).find(".news-events-grid__item:not(.news-events-grid__item--podcast)");
						$(el).append($items);

						$(slider).remove();
					});
			});
		}
	});

	/**
	 * Video Block
	 */
	const $video = $(".wp-block-video video");

	$video.each((index, el) => {
		el.controls = false;
	});

	const setVideoFocus = (videoDom) => {
		if (videoDom?.paused) {
			videoDom.controls = true;
		}

		videoDom.focus();
	};

	const unsetVideoFocus = (videoDom) => {
		if (videoDom?.paused) {
			videoDom.controls = false;
		}
	};

	$($video).on("focus", (e) => {
		setVideoFocus(e.currentTarget);
	}).on("blur", (e) => {
		unsetVideoFocus(e.currentTarget);
	});

	$(".cps-video-overlay").on("click", (e) => {
		const videoDom = $(e.currentTarget).closest(".wp-block-video").find("video")[0];
		setVideoFocus(videoDom);
		videoDom?.play();
	});

	/**
	 * Video Testimonials Block
	 */
	const $testimonial = $(".video-ttm__items");

	const adjustVideoTestimonials = () => {
		if (!$testimonial.length) {
			return;
		}

		$(".video-ttm__item--descr").each((ind, el) => {
			$(el).css("margin-bottom", -el.getBoundingClientRect().height + "px");
		});

		if (window.innerWidth < 783) {
			if (!$testimonial.hasClass("slick-initialized")) {
				$testimonial.slick({
					centerMode: true,
					centerPadding: "35px",
					slidesToShow: 1,
					slidesToScroll: 1,
					dots: true,
					arrows: false,
					infinite: false,
				});
			}
		} else {
			if ($testimonial.hasClass("slick-initialized")) {
				$testimonial.slick("unslick");
			}
		}
	};

	const playTestimonialVideo = ($item, preview = true) => {
		if (!$item.length || $item.hasClass("video-ttm__item---playing")) {
			return;
		}

		const videoDom = $item.find(`.video-ttm__item-${preview ? "preview" : "video"}`).get(0);

		if (videoDom?.play) {
			videoDom.play();
		}

		if (!preview) {
			if (videoDom) {
				videoDom.setAttribute("aria-hidden", "false");
				videoDom.focus();
			}

			$item.closest(".video-ttm__items.slick-slider .slick-slide").addClass("slick--playing");
		}
	};

	const stopTestimonialVideo = ($item, preview = true) => {
		if (
			!$item.length ||
			(preview && $item.hasClass("video-ttm__item---playing")) ||
			(!preview && !$item.hasClass("video-ttm__item---playing"))
		) {
			return;
		}

		const videoDom = $item.find(`.video-ttm__item-${preview ? "preview" : "video"}`).get(0);

		if (videoDom?.pause && videoDom?.currentTime > 0) {
			videoDom.currentTime = 0;
			videoDom.pause();
		}

		if (!preview) {
			if (videoDom) {
				videoDom.setAttribute("aria-hidden", "true");
			}

			$item.closest(".video-ttm__items.slick-slider .slick-slide").removeClass("slick--playing");
		}
	};

	$(document).on("mouseenter", ".video-ttm__item-wrapper", (e) => {
		playTestimonialVideo($(e.target).closest(".video-ttm__item"));
	}).on("mouseleave", ".video-ttm__item-wrapper", (e) => {
		stopTestimonialVideo($(e.target).closest(".video-ttm__item"));
	}).on("focus", ".video-ttm__item--btn-play", (e) => {
		const $item = $(e.currentTarget).closest(".video-ttm__item");

		if ($item.hasClass("video-ttm__item---playing")) {
			return;
		}

		playTestimonialVideo($item);
		$item.addClass("video-ttm__item---focused");
	}).on("blur", ".video-ttm__item--btn-play", (e) => {
		const $item = $(e.currentTarget).closest(".video-ttm__item");

		if ($item.hasClass("video-ttm__item---playing")) {
			return;
		}

		stopTestimonialVideo($item);
		$item.removeClass("video-ttm__item---focused");
	}).on("click", (e) => {
		const $item = $(e.target).closest(".video-ttm__item");

		if ($item.hasClass("video-ttm__item---playing")) {
			return;
		}

		const $playing = $item.closest(".video-ttm__items").find(".video-ttm__item---playing");

		stopTestimonialVideo($playing, false);

		$playing.removeClass("video-ttm__item---playing");

		stopTestimonialVideo($item);
		playTestimonialVideo($item, false);

		$item.addClass("video-ttm__item---playing");
	});

	$(document).on("click", ".video-ttm__item--btn-close", (e) => {
		e.stopPropagation();

		const $item = $(e.target).closest(".video-ttm__item");

		if (!$item.hasClass("video-ttm__item---playing")) {
			return;
		}

		stopTestimonialVideo($item, false);

		$item.removeClass("video-ttm__item---playing");
	});

	/**
	 * Grid Slider.
	 */
	const adjustGridSlider = () => {
		$(".grid-slider").each((idx, el) => {
			const $slider = $(el);

			if (!$slider.length) {
				return;
			}

			let width = $slider.data("width");

			if (!width) {
				width = 0;

				$slider.children().each(function (i, e) {
					width += $(e).width();
				});

				width = width + parseInt($slider.css("gap")) * ($slider.children().length - 1);
				$slider.data("width", width);
			}

			width = Math.max(width, 1200);
			console.log( width, $slider.width() );

			if ($slider.width() < width) {
				if (!$slider.hasClass("slick-initialized")) {
					$slider.slick({
						arrows: false,
						autoplay: false,
						dots: true,
						infinite: false,
						mobileFirst: true,
						slidesToShow: 1,
						slidesToScroll: 1,
						variableWidth: true
					});
				}
			} else if ($slider.hasClass("slick-initialized")) {
				$slider.slick("unslick");
			}
		});
	};

	$(window).resize(() => {
		adjustVideoTestimonials();
		adjustGridSlider();
	});

	$(window).load(() => {
		adjustVideoTestimonials();
		adjustGridSlider();
	});

	// Hides whole column element is member of.
	$('.hide-parent').parent('.wp-block-column, .wp-block-group').hide();

	$('.social-carousel #sbi_images').slick({
		// centerMode: true,
		// centerPadding: '40px',
		centerMode: false,
		mobileFirst: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		dots: false,
		arrows: false,
		infinite: false,
		variableWidth: true,
		responsive: [
			{
				breakpoint: 783,
				settings: "unslick"
			}
		]
	});

	// Enables dismissal of alerts.
	CPSThemeHelpers.enableAlertDismissal( $('#site-alert') );

	const stickyNav = document.querySelector( '.global-header' );

	// Sticky navbar.
	document.addEventListener( 'DOMContentLoaded', function() {
		if ( ! ( 'IntersectionObserver' in window ) ) {
			return;
		}

		if ( ! stickyNav ) {
			return;
		}

		const stickySentinel = document.querySelector( '.header-sticky-sentinel' );

		if ( ! stickySentinel ) {
			return;
		}

		let stickyTimeout, stickTimeout;

		// Intersection Observer for sticky.
		const stickyObserver = new IntersectionObserver( ( entries ) => {
			entries.forEach( entry => {
				if ( 992 > window.innerWidth ) { // quit at 991
					return;
				}

				if ( ! entry.isIntersecting ) {
					clearTimeout( stickyTimeout );
					clearTimeout( stickTimeout );

					stickyNav.classList.add( 'global-header--sticky' );

					// Set new timer for adding the 'stick' class.
					stickTimeout = setTimeout( () => {
						stickyNav.classList.add( 'global-header--stick' );
					}, 200 );
				} else {
					clearTimeout( stickyTimeout );
					clearTimeout( stickTimeout );

					stickyNav.classList.remove( 'global-header--stick' );

					// Set new timer for removing the 'sticky' class.
					stickyTimeout = setTimeout( () => {
						stickyNav.classList.remove( 'global-header--sticky' );
					}, 200 );
				}
			} );
		}, {
			root: null,
			threshold: 0,
			rootMargin: '0px'
		} );

		// Observe the sentinel element.
		stickyObserver.observe( stickySentinel );
	} );

	// Handles appearing of mobile header on scroll up.
	let lastScrollTop = 0;

	$( window ).on( 'scroll', function() {
		const st = $( this ).scrollTop();

		if ( ! stickyNav ) {
			return;
		}

		if ( 992 <= window.innerWidth ) {
			return;
		}

		if ( st > 200 ) {
			stickyNav.classList.add( 'global-header--sticky' );
		} else {
			stickyNav.classList.remove( 'global-header--sticky' );
		}

		if ( st > 1000 ) {
			if ( st > lastScrollTop ) {
				stickyNav.classList.remove( 'global-header--stick' );
			} else {
				stickyNav.classList.add( 'global-header--stick' );
			}
		} else if ( st < 800 ) {
			stickyNav.classList.remove( 'global-header--stick' );
		}

		lastScrollTop = st;
	} );

	// Show the page navigation block on top for mobile.
	const additionalNav = $( '.wp-block-page-navigation' ).first();
	if ( additionalNav.length ) {
		additionalNav.addClass( 'd-none d-lg-block' );
		const clonedNav = additionalNav
			.clone()
			.prependTo( '.wp-block-post-content' )
			.removeClass( 'd-none d-lg-block' )
			.addClass( 'subnav-mobile d-block d-lg-none' );
	}
})(jQuery);

(function() {
	if (CPS.gcseEnabled) {
		var cx = '007201301312168672978:nc6zwrt5yi4';
		var gcse = document.createElement('script');
		gcse.type = 'text/javascript';
		gcse.async = true;
		gcse.src = 'https://cse.google.com/cse.js?cx=' + cx;
		var s = document.getElementsByTagName('script')[0];
		s.parentNode.insertBefore(gcse, s);
	}
})();
