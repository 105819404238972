( function( $ ) {
	$( document ).on( 'ready', function() {
		const $body = $( document.body );
		const $wrapper = $( '.events-calendar' );
		const $overlay = $wrapper.find( '.posts-browser__overlay' );
		const $table = $wrapper.find( '.wp-block-table' );

		const args = $wrapper.data( 'query-args' );
		const filters = { tax: {} }; // The initial state of filters.
		let previousFilters = JSON.stringify( filters ); // Store the initial state of filters.

		// Calendar previous and next month navigation.
		$wrapper.on( 'click', '.events-calendar__nav .wp-block-button__link', function( e ) {
			e.preventDefault();

			// Show the overlay and waiting cursor before the AJAX call.
			$overlay.addClass( 'posts-browser__overlay--visible' );
			$body.css( { 'cursor': 'wait' } );

			const $element = $( this );
			const date = $element.attr( 'data-date' );

			const data = {
				action: 'cps_load_events_calendar',
				nonce: CPS.nonce,
				filters: filters,
				args,
				date,
			};

			$.ajax( {
				url: CPS.ajaxUrl,
				type: 'POST',
				data: data,
				success: function( response ) {
					updateCalendarAndNavigation( response );
				},
				error: function( error ) {
					console.log( 'AJAX call failed', error );
				},
				complete: function() {
					// Remove the overlay class and reset cursor style after the AJAX call finishes.
					$overlay.removeClass( 'posts-browser__overlay--visible' );
					$body.css( { 'cursor': 'default' } );
				}
			} );
		} );

		function updateCalendarAndNavigation( response ) {
			if ( response.success ) {
				// Update the calendar HTML.
				$table.html( response.data.calendar );

				// Update navigation buttons.
				const navData = response.data.navigation;

				// Update previous button.
				const $prevButton = $wrapper.find( '.events-calendar__nav-prev' );
				$prevButton.attr( 'data-date', navData[ 'previous_month' ] );
				$prevButton.prop( 'disabled', ! navData[ 'previous_month_has_events' ] );
				$prevButton.find( '.screen-reader-text' ).text( navData[ 'prev_btn_text' ] );

				// Update next button.
				const $nextButton = $wrapper.find( '.events-calendar__nav-next' );
				$nextButton.attr( 'data-date', navData[ 'next_month' ] );
				$nextButton.prop( 'disabled', ! navData[ 'next_month_has_events' ] );
				$nextButton.find( '.screen-reader-text' ).text( navData[ 'next_btn_text' ] );

				// Update current month.
				$wrapper.find( '.events-calendar__nav-month' ).html( navData[ 'current_month' ] );

				// Update announcement.
				$wrapper.find( '.events-calendar__announcement' ).html( response.data[ 'announcement' ] );
			} else {
				console.log( 'Failed to load calendar data', response );
			}
		}

		// Calendar events filtering.
		$wrapper.on( 'click', '[data-query-arg]', function( e ) {
			e.preventDefault();

			const $element = $( this );
			const queryArg = $element.data( 'query-arg' );
			const value = $element.data( 'value' );

			if ( 'clear' === queryArg ) {
				// Reset filters.
				Object.assign( filters, { tax: {} } );
			} else if ( 'tax' === queryArg ) {
				const taxonomyName = $element.data( 'taxonomy' );
				const taxonomies = filters[ 'tax' ] ? { ...filters[ 'tax' ] } : {};

				// Single select for category.
				if ( ! value ) {
					delete taxonomies[ taxonomyName ];
				} else {
					taxonomies[ taxonomyName ] = value;
				}

				filters[ 'tax' ] = taxonomies;
			} else {
				filters[ queryArg ] = value;
			}

			// Check if the filters have changed, except the clear filters button.
			if ( 'clear' !== queryArg && previousFilters === JSON.stringify( filters ) ) {
				return; // Exit if filters haven't changed.
			}

			// Update the clear button state.
			$wrapper.find( '.frm-clear' ).prop( 'disabled', queryArg === 'clear' );

			// Reset the custom dropdowns.
			if ( 'clear' === queryArg ) {
				$wrapper.trigger( 'clearFilters' );
			}

			// Show the overlay and waiting cursor before the AJAX call.
			$overlay.addClass( 'posts-browser__overlay--visible' );
			$body.css( { 'cursor': 'wait' } );

			const data = {
				action: 'cps_load_events_calendar',
				nonce: CPS.nonce,
				args,
				filters,
			};

			$.ajax( {
				url: CPS.ajaxUrl,
				type: 'POST',
				data: data,
				success: function( response ) {
					updateCalendarAndNavigation( response );

					// Update the previousFilters after successful response.
					if ( response.success ) {
						previousFilters = JSON.stringify( filters );
					}
				},
				error: function( error ) {
					console.log( 'AJAX call failed', error );
				},
				complete: function() {
					// Remove the overlay class and reset cursor style after the AJAX call finishes.
					$overlay.removeClass( 'posts-browser__overlay--visible' );
					$body.css( { 'cursor': 'default' } );
				}
			} );
		} );
	} );
} )( jQuery );
